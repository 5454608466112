import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/media';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';

export const Sections = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
  let newChildren;

  let $count = 1;
  if (Array.isArray(children)) {
    $count = children.length;
    // @ts-ignore
    newChildren = children.map((item, key) => React.cloneElement(item, { $count: $count, key }));
  } else {
    // @ts-ignore
    newChildren = React.cloneElement(children, { $count: $count });
  }

  return (
    <ErrorBoundary message="Atoms\Sections">
      <SectionsContainer>{newChildren}</SectionsContainer>
    </ErrorBoundary>
  );
};

export const Section = ({ children, $count }: SectionProps) => {
  return (
    <ErrorBoundary message="Atoms\Section">
      <SectionContainer $count={$count}>{children}</SectionContainer>
    </ErrorBoundary>
  );
};

const SectionsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
`;

const SectionContainer = styled.section<{ $count: number | undefined }>`
  width: 100%;

  ${({ $count }) =>
    $count === 1 &&
    `
      width: 100%;
    `};

  ${({ $count }) =>
    $count === 2 &&
    `
      ${media.from_laptop`
        width: calc(50% - 6px);
      `}
    `};

  ${({ $count }) =>
    $count === 3 &&
    `
      ${media.from_laptop`
        width: calc(33.3% - 4px);
      `}
    `};

  ${({ $count }) =>
    typeof $count === 'number' &&
    $count > 3 &&
    `
      ${media.from_laptop`
        width: calc(25% - 3px);
      `}
    `};
`;
export interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  $count?: number;
}
